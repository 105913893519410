<template>
  <div class="icon-container">
    <bc-icon class="drawer-icon" @click="open">fa-bars</bc-icon>
    <transition name="fade">
      <div v-if="show" class="drawer-container">
        <div class="row no-gutters">
          <div class="icon-container close-button">
            <bc-icon class="drawer-text-light drawer-icon" @click="close">
              fas fa-times
            </bc-icon>
          </div>
        </div>
        <slot name="header"></slot>
        <div class="row no-gutters items-area">
          <div class="col drawer-items-container">
            <bc-drawer-item
              v-for="item in items"
              :key="item.name"
              :item="item"
            />
          </div>
        </div>
        <slot name="footer"></slot>
      </div>
    </transition>
    <div v-if="show" class="drawer-dismiss" @click="close" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

import { BcIcon } from '../bc-icon'
import BcDrawerItem from './BcDrawerItem.vue'

export default defineComponent({
  name: 'BcDrawer',
  components: { BcDrawerItem, BcIcon },
  props: {
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    toggle() {
      this.show = !this.show
    }
  },
  watch: {
    $route() {
      this.show = false
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

.drawer-text-light {
  color: $extra-light-grey !important;
  cursor: pointer;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.close-button {
  display: flex;
  align-items: center;
}
.drawer-container {
  width: 389px;
  height: 100vh;
  padding: 0 20px;
  background-color: $drawer-background-color;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
}

.drawer-dismiss {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.items-area {
  margin-top: 10px;
}

.drawer-icon {
  font-size: 19px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
