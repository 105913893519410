<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VBtn } from 'vuetify/lib'
export default defineComponent({
  name: 'BcBtn',
  extends: VBtn,
  props: {
    elevation: {
      type: [String, Number],
      default: 0
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-btn {
  &:not(.v-btn--icon) {
    padding: $button-normal-padding !important;
  }

  &:focus {
    transition: $button-focus-transition;
  }

  &:hover {
    transition: $button-hover-transition;
  }

  // primary
  &.primary {
    color: $light;
    background: $primary-gradient;
    &:focus,
    &:hover {
      background: $blue-sea;
    }
  }

  // link blue
  &.link-blue,
  &.secondary {
    background: transparent;
    color: $extra-light-grey;
    &:focus,
    &:hover {
      color: $blue-sea;
    }
  }

  // link grey
  &.link-gray,
  &.tertiary {
    background: transparent;
    color: $light;
    &:focus,
    &:hover {
      color: $blue-sea;
    }
  }
}
</style>
