<template>
  <div
    class="loading__container"
    :class="{ 'loading__container--overlay': blockUi }"
  >
    <div class="loading__circles" :class="size">
      <div class="loading__circles__background"></div>
      <div class="loading__circles__spinner"></div>
      <div class="loading__circles__logo">
        <bc-logo :size="size" />
      </div>
    </div>
    <div v-if="!hideLabel" class="loading__description">
      <span>
        {{ description }}
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import BcLogo from '../bc-logo/BcLogo.vue'

export default defineComponent({
  components: { BcLogo },
  name: 'BcLoading',
  props: {
    size: {
      type: String,
      default: 'lg'
    },
    text: {
      type: String,
      default: null
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    blockUi: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    description() {
      return this.$t('core.misc.loading', [`${this.text || ''}`])
    }
  }
})
</script>

<style lang="scss" scoped>
.loading {
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--overlay {
      position: fixed;
      background-color: rgba(29, 34, 45, 0.85);
      z-index: 10000;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__circles {
    position: relative;

    &.sm {
      width: 38px;
      height: 38px;

      .loading__circles__spinner {
        border: 1px solid transparent;
      }
      .loading__circles__background {
        border: 2px solid transparent;
      }
    }
    &.md {
      width: 102px;
      height: 102px;

      .loading__circles__spinner {
        border: 2px solid transparent;
      }
      .loading__circles__background {
        border: 4px solid transparent;
      }
    }
    &.lg {
      width: 160px;
      height: 160px;
    }

    &__background {
      border-radius: 100%;
      border: 6px solid #1d222d;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    &__spinner {
      border-radius: 100%;
      border: 4px solid transparent;
      border-top-color: #24bbef !important;
      height: calc(100% - 2px);
      width: calc(100% - 2px);
      position: absolute;
      top: 1px;
      left: 1px;
      animation: rotation 0.75s infinite linear;

      @keyframes rotation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    &__logo {
      position: absolute;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__description {
    margin-top: 20px;

    & > span::after {
      content: ' .';
      animation: dots 1s steps(5, end) infinite;

      @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow: 0.4em 0 0 rgba(0, 0, 0, 0), 0.8em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: currentColor;
          text-shadow: 0.4em 0 0 rgba(0, 0, 0, 0), 0.8em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow: 0.4em 0 0 currentColor, 0.8em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow: 0.4em 0 0 currentColor, 0.8em 0 0 currentColor;
        }
      }
    }
  }
}
</style>
