import { ref, watch, onMounted, onUnmounted } from '@vue/composition-api'
import type { Ref } from '@vue/composition-api'

export interface UseTeleport {
  hasDetached: Ref<boolean>
  targetElement: Ref<HTMLElement | null>
  detachContent: () => void
}

const useTeleport = (
  attach: string | HTMLElement | boolean,
  contentElement: Ref<HTMLElement | null>
): UseTeleport => {
  const hasDetached = ref<boolean>(false)
  const targetElement = ref<HTMLElement | null>(null)

  const detachContent = () => {
    const content = contentElement.value

    // Leave menu in place if attached
    // and dev has not changed target
    if (
      !content ||
      hasDetached.value ||
      attach === '' || // If used as a boolean prop (<v-menu attach>)
      attach === true || // If bound to a boolean (<v-menu :attach="true">)
      attach === 'attach' // If bound as boolean prop in pug (v-menu(attach))
    )
      return

    if (!content) return

    let target: HTMLElement | null
    if (attach === false) {
      // Default, detach to app
      target = document.querySelector<HTMLElement>('[data-app]')
    } else if (typeof attach === 'string') {
      // CSS selector
      target = document.querySelector<HTMLElement>(attach)
    } else {
      // DOM Element
      target = attach
    }
    targetElement.value = target

    if (!targetElement.value) {
      console.warn(
        `Unable to locate target '${attach || '[data-app]'}' for bc-popover`
      )
      return
    }

    targetElement.value.appendChild(content)
    hasDetached.value = true
  }

  const removeContent = () => {
    const content = contentElement.value

    try {
      if (content && content.parentNode) {
        content.parentNode.removeChild(content)
      }
    } catch (e) {
      console.warn('Failed to remove content')
    }
  }

  onMounted(() => {
    detachContent()
  })

  onUnmounted(() => {
    removeContent()
  })

  watch(
    () => contentElement.value,
    () => {
      detachContent()
    }
  )
  watch(
    () => attach,
    () => {
      hasDetached.value = false
      detachContent()
    }
  )

  return { hasDetached, targetElement, detachContent }
}

export { useTeleport }
