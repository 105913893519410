export default {
  ui: {
    notEnabled: 'Not enabled',
    alert: {
      info: 'Info',
      warning: 'Warning',
      error: 'Error',
      success: 'Success',
      close: 'Close'
    }
  }
}
