<template>
  <transition name="bc-slide-fade">
    <div v-if="show" role="alert" class="bc-alert" :class="className">
      <div class="bc-alert__container">
        <header class="bc-alert__header">
          <bc-icon size="14" aria-hidden="true">{{ icon }}</bc-icon>
          <h2 class="bc-alert__title">{{ alertTitle }}</h2>
        </header>
        <div class="bc-alert__content">
          <slot></slot>
        </div>
      </div>
      <div class="bc-alert__actions">
        <slot name="actions"></slot>
      </div>

      <bc-btn
        v-if="dismissible"
        icon
        x-small
        class="bc-alert__close"
        :aria-label="$t('ui.alert.close')"
        @click="() => (show = !show)"
      >
        <bc-icon size="14">fas fa-times</bc-icon>
      </bc-btn>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from '@vue/composition-api'
import type { PropType } from '@vue/composition-api'

import { BcBtn } from '../bc-btn'
import { BcIcon } from '../bc-icon'

export type AlertType = 'success' | 'info' | 'warning' | 'error'

const iconByType: Record<AlertType, string> = {
  info: 'fas fa-info-circle',
  warning: 'fas fa-exclamation-triangle',
  error: 'fas fa-times-hexagon',
  success: 'fas fa-check-square'
}

export default defineComponent({
  name: 'BcAlert',
  components: { BcBtn, BcIcon },
  props: {
    type: {
      type: String as PropType<AlertType>,
      default: 'info',
      validator: (value: string) =>
        ['success', 'info', 'warning', 'error'].includes(value)
    },
    title: {
      type: String,
      default: undefined
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: undefined
    }
  },
  setup(props, { root, emit }) {
    const _show = ref(true)
    const show = computed({
      get: () => props.value ?? _show.value,
      set: (value) => {
        emit('input', value)
        _show.value = value
      }
    })
    const className = computed(() => ({
      'bc-alert--dismissible': props.dismissible,
      [props.type]: true
    }))
    const alertTitle = computed(
      () => props.title || root.$t(`ui.alert.${props.type}`)
    )
    const icon = computed(() => iconByType[props.type])

    return { className, alertTitle, icon, show }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

.bc-alert {
  padding: 20px 20px 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__close {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  &__header {
    display: flex;
  }
  &__title {
    margin-left: 12px;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
  }

  &__content {
    margin-top: 8px;
    font-size: 12px;
  }

  &__actions {
    display: flex;
  }

  &--dismissible {
    padding-right: 40px;
  }
}
</style>
