import { reactive } from '@vue/composition-api'

import { PluginOptions } from './types'

export interface DialogButtonProps {
  label?: string
  color?: string
}

export interface ConfirmMessageOptions {
  type?: 'info' | 'warning' | 'error' | 'success'
  title: string
  icon?: string
  subtitle?: string
  caption?: string
  persistent?: boolean
  confirm?: DialogButtonProps
  cancel?: DialogButtonProps
}

export interface DialogMessage extends ConfirmMessageOptions {
  id: number
  onConfirmCallback: () => Promise<void>
  onCancelCallback: () => Promise<void>
}

export type ConfirmDialogAPI = (
  options: ConfirmMessageOptions,
  _onConfirmCallback: () => Promise<void>,
  _onCancelCallback?: () => Promise<void>
) => Promise<boolean>

export type ConfirmDialogPlugin = ConfirmDialogAPI & {
  __dialogMessages: DialogMessage[]
}

class ConfirmDialog {
  dialogMessageId: number
  dialogMessages: DialogMessage[]

  constructor() {
    this.dialogMessageId = 0
    this.dialogMessages = reactive<DialogMessage[]>([])
  }

  getPlugin() {
    const plugin = this.confirm.bind(this) as ConfirmDialogPlugin
    plugin.__dialogMessages = this.dialogMessages

    return plugin
  }

  popMessage(id: number): void {
    const index = this.dialogMessages.findIndex((m) => m.id === id)
    if (index !== -1) {
      this.dialogMessages.splice(index, 1)
    }
  }

  confirm(
    options: ConfirmMessageOptions,
    _onConfirmCallback: () => Promise<void>,
    _onCancelCallback?: () => Promise<void>
  ): Promise<boolean> {
    return new Promise((resolve) => {
      const id = this.dialogMessageId++
      this.dialogMessages.push({
        id,
        icon: 'fa-exclamation-circle',
        onConfirmCallback: async () => {
          await _onConfirmCallback()
          this.popMessage(id)
          resolve(true)
        },
        onCancelCallback: async () => {
          if (_onCancelCallback) {
            await _onCancelCallback()
          }
          this.popMessage(id)
          resolve(false)
        },
        ...options
      })
    })
  }
}

export default {
  install({ $brain }: PluginOptions): void {
    if (this.__installed) return

    this.__instance = new ConfirmDialog()
    $brain.confirm = this.__instance.getPlugin()

    this.__installed = true
  },
  __instance: undefined as ConfirmDialog | undefined,
  __installed: false
}
