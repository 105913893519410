<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VBreadcrumbsItem } from 'vuetify/lib'
export default defineComponent({
  name: 'BcBreadcrumbsItem',
  extends: VBreadcrumbsItem
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-breadcrumbs__item {
  font-size: 12px;
  text-transform: uppercase;
  color: $extra-light-grey;

  &.v-breadcrumbs__item--disabled {
    color: $blue-sea-2;
  }
}
</style>
