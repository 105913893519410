<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VCard } from 'vuetify/lib'
export default defineComponent({
  name: 'BcCard',
  extends: VCard,
  props: {
    color: {
      type: String,
      default: 'brain'
    },
    elevation: {
      type: [Number, String],
      default: 0
    },
    rounded: {
      type: [Boolean, String],
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-card.brain {
  background: $medium-grey;
  padding: 12px 20px;
}
</style>
