import { toKebabCase } from './string'

/**
 * Get an object with all keys prefixed with a given string.
 * The filter is casing invariant.
 *
 * @param obj object to get the values from.
 * @param prefix prefix to search
 *
 * @example
 * filterObjectByPrefix(
 *   {
 *     'pre-fix:foo': 'bar',
 *     'preFix:baz': 'qux',
 *     'PreFix:quux': 'corge',
 *     'baz': 'qux',
 *   },
 *  'pre-fix'
 * ) // { 'foo': 'bar', 'baz': 'qux', 'quux': 'corge' }
 */
export const filterObjectByPrefix = (
  obj: Record<string, any>,
  prefix: string
): Record<string, any> => {
  const _prefix = toKebabCase(prefix)
  const attrs = Object.keys(obj || {}).reduce((result, attrName) => {
    const kebabName = toKebabCase(attrName)
    if (kebabName.startsWith(_prefix)) {
      result[kebabName.replace(_prefix, '')] = obj[attrName]
    }
    return result
  }, {} as Record<string, any>)

  return attrs
}
