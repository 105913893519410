<template>
  <div class="bc-box-selector" :style="cssVars">
    <div
      class="bc-box-selector__item"
      :class="getBoxSelectorStateClass(boxSelectorItem)"
      :key="boxSelectorItem.name"
      v-for="boxSelectorItem in options"
    >
      <bc-tooltip
        top
        v-bind="$attrs"
        v-on="$listeners"
        :disabled="!tooltipEnabled"
      >
        <template v-slot:activator="tooltip">
          <bc-card
            color="medium-grey-4"
            :ripple="!boxSelectorItem.disabled"
            v-bind="tooltip.attrs"
            v-on="tooltip.on"
            class="bc-box-selector__item--card"
            :height="boxHeight"
            :width="boxWidth"
            @click="
              !boxSelectorItem.disabled
                ? $emit('change', boxSelectorItem)
                : null
            "
          >
            <bc-icon
              class="bc-box-selector__item--card--icon"
              :size="iconSize"
              >{{ boxSelectorItem.icon }}</bc-icon
            >
            <p class="bc-box-selector__item--card--name">
              {{ boxSelectorItem.name }}
            </p>
            <p
              v-if="boxSelectorItem.disabled"
              class="bc-box-selector__item--card--not-enabled"
            >
              {{ $t('ui.notEnabled') }}
            </p>
          </bc-card>
        </template>
        <span>{{ boxSelectorItem.name }}</span>
      </bc-tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from '@vue/composition-api'

import BcCard from '../bc-card/BcCard.vue'
import BcIcon from '../bc-icon/BcIcon.vue'
import BcTooltip from '../bc-tooltip/BcTooltip.vue'
import { BoxSelectorItemModel } from './BoxSelectorItemModel'

export default defineComponent({
  name: 'BcBoxSelector',
  components: { BcCard, BcIcon, BcTooltip },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Object
    },
    boxHeight: {
      type: [String, Number],
      default: 110
    },
    boxWidth: {
      type: [String, Number],
      default: 100
    },
    iconSize: {
      type: [String, Number],
      default: 36
    },
    boxColumns: {
      type: [String, Number],
      default: 3
    },
    tooltipEnabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const getBoxSelectorStateClass = (
      boxSelectorItem: BoxSelectorItemModel
    ) => {
      return {
        disabled: boxSelectorItem.disabled,
        active: props.value?.key === boxSelectorItem.key
      }
    }
    const cssVars = computed(() => {
      return {
        '--grid-template-columns-quantity': props.boxColumns
      }
    })
    return {
      cssVars,
      getBoxSelectorStateClass
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.bc-box-selector {
  display: grid;
  grid-template-columns: repeat(var(--grid-template-columns-quantity), 1fr);
  margin: -5px -10px;
  &__item {
    margin: 5px 10px;

    &:hover {
      .bc-box-selector__item--card {
        &--name,
        &--icon {
          color: $light;
        }
      }
    }

    &.active {
      .bc-box-selector__item--card {
        &--name,
        &--icon {
          color: $blue;
        }
      }
    }

    &.disabled {
      .bc-box-selector__item--card {
        &:focus:before {
          content: none;
        }

        &--name,
        &--icon {
          color: $extra-light-grey !important;
        }

        &--not-enabled {
          color: $light;
          margin-bottom: 0;
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }

    &--card {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border-radius: 0;
      padding: 0 6px;

      &--name,
      &--icon {
        color: $light-grey;
      }

      &--name {
        margin-top: 3px;
        margin-bottom: 0;
        font-size: 12px;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: Ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 16px;
      }

      &--icon:focus::after {
        opacity: 0;
      }
    }
  }
}
</style>
