<template>
  <v-app v-bind="$attrs" v-on="$listeners">
    <bc-confirm-dialog-provider></bc-confirm-dialog-provider>
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-app>
</template>
<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VApp } from 'vuetify/lib'

import { BcConfirmDialogProvider } from '../bc-confirm-dialog'
export default defineComponent({
  name: 'BcApp',
  components: { VApp, BcConfirmDialogProvider }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-application {
  background: $application-body-background;
}
</style>
