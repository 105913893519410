<template>
  <bc-confirm-dialog
    :value="!!message.title"
    :type="message.type"
    :icon="message.icon"
    :title="message.title"
    :subtitle="message.subtitle"
    :caption="message.caption"
    :persistent="message.persistent"
    :confirmLabel="message.confirm.label"
    :confirmColor="message.confirm.color"
    :cancelLabel="message.cancel.label"
    :cancelColor="message.cancel.color"
    @confirm="message.onConfirmCallback"
    @cancel="message.onCancelCallback"
  ></bc-confirm-dialog>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from '@vue/composition-api'

import BcConfirmDialog from './BcConfirmDialog.vue'

const baseDialog = {
  confirm: {},
  cancel: {},
  onConfirmCallback: () => void 0,
  onCancelCallback: () => void 0
}

export default defineComponent({
  name: 'BcConfirmDialogProvider',
  components: { BcConfirmDialog },
  setup(_, { root }) {
    const $brain = (root as any).$brain
    const dialogMessages = $brain.confirm.__dialogMessages
    const message = ref()
    watch(
      dialogMessages,
      () => {
        if (dialogMessages.length) {
          message.value = {
            ...baseDialog,
            ...dialogMessages[dialogMessages.length - 1]
          }
        } else {
          message.value = baseDialog
        }
      },
      {
        immediate: true,
        deep: true
      }
    )

    return { message }
  }
})
</script>

<style></style>
