<template>
  <div>
    <bc-dialog
      :value="show"
      :width="width"
      :persistent="persistent"
      @input="onCancelHandler"
    >
      <bc-card color="medium-grey" :height="height">
        <div class="confirm-header">
          <div>
            <bc-btn
              v-if="!persistent"
              icon
              small
              class="confirm-header__close-button"
              @click="onCancelHandler"
            >
              <bc-icon class="confirm-header__close-icon">fa-times</bc-icon>
            </bc-btn>
          </div>
        </div>
        <bc-message
          :type="type"
          :icon="icon"
          :title="title"
          :subtitle="subtitle"
          :caption="caption"
          :actions="[
            {
              type: 'button',
              label: _cancelLabel,
              color: _cancelColor,
              callback: onCancelHandler
            },
            {
              type: 'button',
              label: _confirmLabel,
              color: _confirmColor,
              callback: onConfirmHandler
            }
          ]"
        >
        </bc-message>
      </bc-card>
    </bc-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from '@vue/composition-api'

import { BcBtn } from '../bc-btn'
import { BcCard } from '../bc-card'
import { BcDialog } from '../bc-dialog'
import { BcIcon } from '../bc-icon'
import { BcMessage } from '../bc-message'

export default defineComponent({
  name: 'BcConfirmDialog',
  components: { BcDialog, BcCard, BcBtn, BcIcon, BcMessage },
  emits: ['confirm', 'cancel', 'input'],
  props: {
    type: {
      type: String,
      default: 'warning'
    },
    value: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    title: {
      type: String,
      require: true
    },
    subtitle: {
      type: String,
      default: null
    },
    caption: {
      type: String,
      default: null
    },
    confirmLabel: {
      type: String,
      default: null
    },
    confirmColor: {
      type: String,
      default: null
    },
    cancelLabel: {
      type: String,
      default: null
    },
    cancelColor: {
      type: String,
      default: null
    },
    width: {
      type: [String, Number],
      default: 800
    },
    height: {
      type: [String, Number],
      default: 600
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const root = ctx.root as any
    const show = ref(props.value)

    watch(
      () => props.value,
      (value) => {
        if (value !== show.value) {
          show.value = value
        }
      }
    )

    watch(show, (_show) => {
      if (_show !== props.value) {
        ctx.emit('input', _show)
      }
    })

    const _confirmLabel = computed(
      () => props.confirmLabel || root.$t('core.misc.confirm')
    )
    const _confirmColor = computed(() => props.confirmColor || 'primary')

    const _cancelLabel = computed(
      () => props.cancelLabel || root.$t('core.misc.cancel')
    )
    const _cancelColor = computed(() => props.cancelColor || 'link-blue')

    const onConfirmHandler = () => {
      if (show.value) {
        ctx.emit('confirm')
        show.value = false
      }
    }
    const onCancelHandler = () => {
      if (show.value) {
        ctx.emit('cancel')
        show.value = false
      }
    }

    return {
      show,
      _confirmLabel,
      _confirmColor,
      _cancelLabel,
      _cancelColor,
      onConfirmHandler,
      onCancelHandler
    }
  }
})
</script>

<style lang="scss">
@import '../../styles/brain-ui-variables.scss';

.confirm-header {
  display: flex;
  justify-content: flex-end;
  padding: 5px 5px;
  height: 50px;

  & &__close-button {
    padding: 0 !important;
  }

  & &__close-icon {
    font-size: 16px !important;
    color: $extra-light-grey !important;
  }
}
</style>
