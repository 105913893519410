<template>
  <v-stepper-header
    v-bind="$attrs"
    v-on="$listeners"
    :class="backgroundColor.className"
    :style="backgroundColor.style"
  >
    <div v-if="!hideProgressBar" class="bc-stepper__header__progress-bar"></div>
    <slot></slot>
  </v-stepper-header>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VStepperHeader } from 'vuetify/lib'

import { useBackgroundColor } from '../../composables'

export default defineComponent({
  name: 'BcStepperHeader',
  components: { VStepperHeader },
  props: {
    color: {
      type: String,
      default: 'layer-2'
    },
    hideProgressBar: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const backgroundColor = useBackgroundColor(props)

    return { backgroundColor }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

.v-stepper__header {
  position: relative;
}

.bc-stepper__header__progress-bar {
  position: absolute;
  inset: 0 76px;

  &::before {
    content: '';
    background-color: $black;
    height: 4px;
    display: block;
    margin-top: 25px;
  }

  &::after {
    content: '';
    background-color: $blue;
    height: 4px;
    display: block;
    margin-top: -4px;
    width: var(--bc-stepper-progress);
    transition: width 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}
</style>
