export default {
  ui: {
    notEnabled: 'Desabilitado',
    alert: {
      info: 'Aviso',
      warning: 'Alerta',
      error: 'Erro',
      success: 'Sucesso',
      close: 'Fechar'
    }
  }
}
