<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VTimePicker } from 'vuetify/lib'
export default defineComponent({
  name: 'BcTimePicker',
  extends: VTimePicker
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-picker {
  background: $primary-gray;
  width: 290px;

  .v-picker__title {
    .v-time-picker-title {
      color: $blue;
    }
  }

  .v-picker__body {
    .v-time-picker-clock__container {
      background: $medium-grey;
      .v-time-picker-clock__ampm {
        .v-picker__title__btn {
          color: $blue;
        }
      }
      .v-time-picker-clock {
        background: $medium-grey-2;
        .v-time-picker-clock__inner {
          .v-time-picker-clock__item {
            color: $light-grey;
          }
        }
      }
    }
  }
}
</style>
