<template>
  <v-date-picker
    v-bind="$attrs"
    v-on="$listeners"
    :events="allEvents"
    :locale="lang"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-date-picker>
</template>

<script lang="ts">
import { computed, defineComponent } from '@vue/composition-api'
import { VDatePicker } from 'vuetify/lib'

import { useCalendarEvents } from '../../providers'

export default defineComponent({
  name: 'BcDatePicker',
  components: { VDatePicker },
  props: {
    events: {
      type: [Array, Function, Object],
      default: () => ({})
    },
    locale: {
      type: String,
      default: undefined
    }
  },
  setup(props, { root }) {
    const calendarEvents = useCalendarEvents()
    const globalEvents = computed(() => {
      const events = calendarEvents.value.reduce((result, evt) => {
        const date = new Date(evt.date).toISOString().substr(0, 10)
        if (!result[date]) {
          result[date] = []
        }
        result[date].push(evt.color!)
        return result
      }, {} as Record<string, string[]>)

      return events
    })
    const allEvents = computed(() => {
      return typeof props.events === 'object' && props.events !== null
        ? { ...globalEvents.value, ...props.events }
        : props.events
    })

    const lang = computed(() => props.locale ?? (root as any).$i18n?.locale)

    return { allEvents, lang }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-picker {
  background: $primary-gray;

  ::v-deep .v-date-picker-title {
    color: $blue;
  }

  ::v-deep .v-picker__body {
    .v-date-picker-table,
    .v-date-picker-header {
      background: $medium-grey;
    }

    .v-date-picker-header {
      .v-btn {
        color: $light-grey;
      }

      .v-icon::after,
      .button:not(:hover):not(:focus),
      .v-date-picker-years,
      .v-date-picker-table,
      .v-date-picker-header {
        background: $medium-grey;
      }

      .v-date-picker-table {
        .v-date-picker-table--date__week,
        .v-btn {
          color: $light-grey;
        }
      }
    }
  }
}
</style>
