<template>
  <div
    class="dropzone"
    :class="{
      'dropzone--hover': hover,
      'dropzone--invalid-file': invalidFile
    }"
    :style="{ height }"
    @dragenter="onDragEnterHandler"
    @dragover="onDragOverHandler"
    @dragleave="onDragLeaveHandler"
    @drop="onDropHanlder"
  >
    <input
      ref="fileInput"
      title="fileInput"
      class="dropzone__input"
      type="file"
      :accept="acceptExtensions"
      @change="onFileChangeHandler"
    />
    <bc-message
      v-if="isEmpty"
      type="primary"
      :icon="icon"
      :title="title"
      :actions="[
        {
          type: 'button',
          label: actionLabel,
          color: 'primary',
          callback: onImportHandler
        }
      ]"
    >
    </bc-message>
    <div
      v-else
      class="dropzone__file-list"
      v-for="file in value || []"
      :key="file.name"
    >
      <div class="file-item">
        <bc-btn
          icon
          class="file-item__close-btn"
          color="light-grey"
          @click.prevent="onRemoveFileHandler(file.name)"
        >
          <bc-icon>fa fa-times</bc-icon>
        </bc-btn>
        <div class="file-item__details">
          <bc-icon color="primary" class="file-item__icon"
            >fas fa-file-spreadsheet</bc-icon
          >
          <div class="file-item__name">
            {{ file.name }}
          </div>
          <div class="file-item__size">
            {{ convertSize(file.size) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from '@vue/composition-api'

import { BcBtn } from '../bc-btn'
import { BcIcon } from '../bc-icon'
import { BcMessage } from '../bc-message'

export default defineComponent({
  name: 'BcDropzone',
  components: { BcMessage, BcIcon, BcBtn },
  props: {
    value: {
      type: Array
    },
    title: {
      type: String
    },
    actionLabel: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'fa-copy'
    },
    height: {
      type: String,
      default: 'auto'
    },
    accept: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const fileInput = ref<HTMLInputElement | null>(null)
    const hover = ref<boolean>(false)
    const invalidFile = ref<boolean>(false)

    const isValidFile = (file: File | null | undefined) => {
      if (!file) return false
      if (props.accept.length === 0) return true

      const extensions = props.accept as string[]
      return extensions.some((ext) => file.name.endsWith(ext))
    }

    const toFileArray = (files: FileList | null | undefined): File[] => {
      if (!files) return []

      const fileArray: File[] = []
      for (let i = 0; i < files.length; i++) {
        if (isValidFile(files[i])) {
          fileArray.push(files[i])
        }
      }

      return fileArray
    }

    const mergeFileList = (files: File[]) => {
      if (!props.multiple) return [files[0]]

      const selectedFiles = [...((props.value || []) as File[])]
      files.forEach((file) => {
        const fileIndex = selectedFiles.findIndex((f) => f.name === file.name)
        if (fileIndex === -1) {
          selectedFiles.push(file)
        } else {
          selectedFiles.splice(fileIndex, 1, file)
        }
      })

      return selectedFiles
    }

    const convertSize = (bytes: number) => {
      let value = bytes
      let unit = 'b'
      if (bytes > 1e6) {
        value = bytes / 1048576
        unit = 'mb'
      } else if (bytes > 1e3) {
        value = bytes / 1024
        unit = 'kb'
      }
      return `${value.toFixed(2)} ${unit}`
    }

    const noPropagation = (event: Event) => {
      event.stopPropagation()
      if (event.preventDefault) {
        return event.preventDefault()
      } else {
        return (event.returnValue = false)
      }
    }

    const acceptExtensions = computed(() => props.accept.join(',') || undefined)
    const isEmpty = computed(() => !props.value || props.value.length === 0)

    const onImportHandler = () => {
      fileInput.value?.click()
    }

    const onFileChangeHandler = (event: Event) => {
      const target = event.target as HTMLInputElement
      const files = toFileArray(target.files)
      ctx.emit('input', mergeFileList(files))
      fileInput.value!.value = ''
    }

    const onRemoveFileHandler = (fileName: string) => {
      const files = props.value as File[]
      ctx.emit(
        'input',
        files.filter((file: File) => file.name !== fileName)
      )
    }

    const onDragEnterHandler = (event: DragEvent) => {
      noPropagation(event)
    }

    const onDragOverHandler = (event: DragEvent) => {
      noPropagation(event)
      hover.value = true
    }

    const onDragLeaveHandler = (event: DragEvent) => {
      noPropagation(event)
      hover.value = false
    }

    const onDropHanlder = async (event: DragEvent) => {
      // Makes it possible to drag files from chrome's download bar
      // http://stackoverflow.com/questions/19526430/drag-and-drop-file-uploads-from-chrome-downloads-bar
      // Try is required to prevent bug in Internet Explorer 11 (SCRIPT65535 exception)
      let effect: DataTransfer['effectAllowed'] | undefined
      try {
        effect = event.dataTransfer?.effectAllowed
      } catch (error) {
        void error
      }

      if (event.dataTransfer) {
        event.dataTransfer.dropEffect =
          effect === 'move' || effect === 'linkMove' ? 'move' : 'copy'
      }

      noPropagation(event)

      const files = toFileArray(event.dataTransfer?.files)
      ctx.emit('input', mergeFileList(files))
      hover.value = false
    }

    return {
      fileInput,
      hover,
      invalidFile,
      acceptExtensions,
      isEmpty,
      convertSize,
      onImportHandler,
      onFileChangeHandler,
      onRemoveFileHandler,
      onDragEnterHandler,
      onDragOverHandler,
      onDragLeaveHandler,
      onDropHanlder
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

.dropzone {
  padding: 5px;
  border: dashed 1px #{$blue-sea}80;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  &--hover {
    border: solid 1px #{$blue-sea}80;
  }

  &--invalid-file {
    border: solid 1px #{$red}80;
    cursor: no-drop;
  }

  &__input {
    display: none;
  }
}

.file-item {
  width: 200px;
  padding: 30px 15px;
  position: relative;

  &__details {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1;
  }

  &__icon {
    font-size: 62px !important;
  }

  &__name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }

  &__size {
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 20px;
  }

  &__close-btn {
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
