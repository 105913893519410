<template>
  <div>
    <form-wizard
      @on-change="onChange"
      @on-complete="onComplete"
      class="wizard-form-bg"
      color="var(--bc-primary)"
      errorColor="var(--bc-danger)"
      ref="wizard"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <!--<template slot="step" scope="props">
        <wizard-step
          :tab="props.tab"
          :transition="props.transition"
          :key="props.tab.title"
          :index="props.index"
        >
        </wizard-step>
      </template>-->
      <slot></slot>
      <template v-slot:footer="props">
        <slot name="footer" v-bind="props">
          <div>
            <div
              class="wizard__footer"
              v-if="!(props.isLastStep && hideButtonsOnLastStep) && showFooter"
            >
              <bc-btn
                v-if="props.activeTabIndex === 0 && hasCancel"
                color="link-blue"
                @click.prevent="onCancel"
              >
                {{ $t('core.wizard.cancel') }}
              </bc-btn>
              <bc-btn
                v-if="props.activeTabIndex > 0 && !disableButtonBack"
                color="primary"
                @click.prevent="onBackAction(props)"
              >
                {{ $t('core.wizard.back') }}
              </bc-btn>

              <bc-btn
                v-if="props.activeTabIndex < tabCount - 2"
                :disabled="disableButtonNext"
                @click="onNextAction(props)"
                color="primary"
              >
                {{ $t('core.wizard.next') }}
              </bc-btn>
              <bc-btn v-else @click="onConfirm" color="primary">
                {{ $t('core.wizard.finish') }}
              </bc-btn>
            </div>
          </div>
        </slot>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { FormWizard } from 'vue-form-wizard'

import { BcBtn } from '../bc-btn'

export default defineComponent({
  name: 'BcWizard',
  components: { BcBtn, FormWizard },
  props: {
    saveAction: { type: Function, required: true },
    disableButtonNext: { type: Boolean, default: false },
    disableButtonBack: { type: Boolean, default: false },
    hideButtonsOnLastStep: { type: Boolean, default: false },
    showFooter: { type: Boolean, default: true },
    backAction: { type: Function },
    nextAction: { type: Function }
  },
  data() {
    return {
      currentTab: 0
    }
  },
  methods: {
    reset() {
      this.$refs.wizard.reset()
    },
    activateAll() {
      this.$refs.wizard.activateAll()
    },
    nextTab() {
      this.$refs.wizard.nextTab()
    },
    prevTab() {
      this.$refs.wizard.prevTab()
    },
    changeTab(oldIndex, newIndex) {
      this.$refs.wizard.changeTab(oldIndex, newIndex)
    },
    onComplete(...args) {
      this.$emit('complete', ...args)
    },
    onChange(...args) {
      this.currentTab = this.$refs.wizard.activeTabIndex
      this.$emit('change', args)
    },
    onCancel() {
      this.$emit('cancel', this.currentTab)
    },
    onBackAction(props) {
      if (this.backAction instanceof Function)
        this.backAction(props, this.$refs.wizard)
      else props.prevTab()
    },
    onNextAction(props) {
      if (this.nextAction instanceof Function)
        this.nextAction(props, this.$refs.wizard)
      else props.nextTab()
    },
    async onConfirm() {
      if (this.$listeners?.confirm) {
        await this.$listeners.confirm()
      }
      this.nextTab()
    }
  },
  computed: {
    hasCancel() {
      return !!this.$listeners && !!this.$listeners.cancel
    },
    tabCount() {
      return this.$slots?.default?.length || 0
    }
  }
})
</script>
<style lang="scss" scoped>
.wizard {
  &__footer {
    display: flex;
    justify-content: flex-end;

    & > :not(:first-child) {
      margin-left: 10px;
    }
  }
}
</style>
