<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VCheckbox } from 'vuetify/lib'
export default defineComponent({
  name: 'BcCheckbox',
  extends: VCheckbox,
  props: {
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-input--selection-controls__input {
  .fas.fa-check-square {
    &::after {
      content: '';
      background: $light;
      position: absolute;
      opacity: 1;
      top: 6px;
      bottom: 6px;
      left: 6px;
      right: 6px;
      border-radius: 2px;
      height: unset;
      width: unset;
      z-index: 1;
    }
    &::before {
      z-index: 2;
    }
  }
}

.v-input--checkbox {
  &.v-input--is-label-active {
    .v-icon {
      color: $checkbox_active_color !important;
    }
  }

  &:not(.v-input--is-label-active) {
    .v-icon {
      color: $checkbox_disable_color;
    }
  }

  .v-icon {
    font-size: $checkbox-button-icon-size;
  }

  label {
    color: $light-grey !important;
    font-size: 14px;
  }
}
</style>
