<template>
  <v-form v-bind="$attrs" v-on="$listeners">
    <slot :form="form"></slot>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted } from '@vue/composition-api'
import type { PropType } from '@vue/composition-api'
import { VForm } from 'vuetify/lib'

const getKey = (event: Event | string) =>
  event instanceof Event ? (event.target as HTMLInputElement).name : event

const getValue = (event: Event | string, value: any, type: string) => {
  if (event instanceof Event) {
    const targetEvent = event.target as HTMLInputElement
    if (targetEvent.type === 'checkbox' && targetEvent.checked !== undefined) {
      return !!targetEvent.checked
    }
    return targetEvent.value
  } else if (type === 'boolean' || type === 'toggle' || type === 'checkbox') {
    return !!value
  } else if (type === 'numeric') {
    return !value ? null : value
  }
  return value
}

export default defineComponent({
  name: 'BcForm',
  components: { VForm },
  props: {
    initialValues: {
      type: Object as PropType<Record<string, any>>,
      require: true
    },
    validateForm: {
      type: Function,
      default: null
    },
    yupSchema: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const initialValues = props.initialValues as Record<string, any>
    const validateForm = props.validateForm as (form: any) => boolean | null
    const errors: Record<string, string | null> = {}
    const valid: Record<string, boolean> = {}
    const touched: Record<string, boolean> = {}
    const dirty: Record<string, boolean> = {}

    Object.keys(initialValues).forEach((key) => {
      errors[key] = null
      valid[key] = true
      touched[key] = false
      dirty[key] = false
    })

    const handleBlur = (e: Event | string) => {
      const key = getKey(e)
      setFieldTouched(key, true)
      setFieldDirty(key, true)
    }

    const handleChange = async (e: Event | string, v: any, type: string) => {
      const key = getKey(e)
      const value = getValue(e, v, type)
      setFieldValue(key, value)
      emit('value', { key, value, form: form })
      await validate(false)
    }

    const setFieldValue = (key: string, value: any) => {
      form.values[key] = value === undefined ? null : value
      setFieldTouched(key, true)
      setFieldDirty(key, true)
    }

    const setFieldTouched = (key: string, touched: boolean) =>
      (form.touched[key] = touched)

    const setFieldDirty = (key: string, dirty: boolean) =>
      (form.dirty[key] = dirty)

    const setSubmitting = (submitting: boolean) =>
      (form.isSubmitting = submitting)

    const setFieldError = (key: string, error: string | null) => {
      form.errors[key] = error
      form.valid[key] = error === null
    }

    const setFormErrors = (errors: Record<string, string | null>) => {
      setIsValid(true)
      Object.keys(initialValues).forEach((key) => {
        setFieldError(key, errors[key] || null)

        if (!form.valid[key]) {
          setIsValid(false)
        }
      })
    }

    const setIsValid = (isValid: boolean) => (form.isValid = isValid)

    const getForm = () => form

    const setAllDirty = (dirty: boolean) => {
      const keys = Object.keys(initialValues)
      keys.forEach((key) => {
        setFieldDirty(key, dirty)
      })
    }

    const setAllTouched = (touched: boolean) => {
      const keys = Object.keys(initialValues)
      keys.forEach((key) => {
        setFieldTouched(key, touched)
      })
    }

    const resetForm = () => {
      Object.assign(form.values, initialValues)
      const keys = Object.keys(initialValues)
      keys.forEach((key) => {
        setFieldTouched(key, false)
        setFieldDirty(key, false)
      })
    }

    const validate = async (markAllTouched = true) => {
      setIsValid(true)

      if (markAllTouched) {
        setAllTouched(true)
      }
      if (validateForm) {
        return validateForm(form)
      }
      if (props.yupSchema) {
        const yupErrors: Record<string, string | null> = {}
        Object.keys(form.values).forEach((key) => {
          yupErrors[key] = null
        })
        try {
          await props.yupSchema.validate(form.values, { abortEarly: false })
        } catch (e: any) {
          e.inner.forEach((error: { path: string; message: string }) => {
            yupErrors[error.path] = error.message
          })
        }
        setFormErrors(yupErrors)
      }
      return form.isValid
    }

    const form = reactive({
      onBlur: handleBlur,
      onChange: handleChange,
      onInput: handleChange,
      setSubmitting: setSubmitting,
      resetForm,
      validate,
      setFieldValue,
      setIsValid,
      setFieldError,
      setFieldTouched,
      setFieldDirty,
      setFormErrors,
      setAllDirty,
      setAllTouched,
      values: Object.assign({}, initialValues || {}),
      isValid: true,
      isSubmitting: false,
      errors,
      valid,
      touched,
      dirty
    })

    onMounted(() => validate(false))

    return { form, getForm }
  }
})
</script>
