<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VAutocomplete } from 'vuetify/lib'
export default defineComponent({
  name: 'BcAutocomplete',
  extends: VAutocomplete,
  props: {
    persistentPlaceholder: {
      type: Boolean,
      default: true
    }
  }
})
</script>
