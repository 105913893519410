import moment from 'moment'

export const monthFormat = 'YYYY-MM'

export const isValidDate = (date: string | Date | null): boolean => {
  return moment(date, true).isValid()
}

export const isValidMonthDate = (date: string | Date | null): boolean => {
  return moment(date, monthFormat, true).isValid()
}

export const parseDate = (
  dateStr: string | null,
  format: string,
  strict = true
): string | null => {
  if (!dateStr) return null
  const date = moment(dateStr, format, strict)
  return date.isValid() ? date.toISOString() : dateStr
}

export const formatDate = (
  date: Date | string | null,
  format: string
): string | null => {
  if (!date || !isValidDate(date)) return null

  return moment(date).format(format)
}

export const parsePickerDate = (date: string | null): string | null => {
  return parseDate(date, monthFormat)
}

export const toDateString = (date: Date | string): string =>
  moment(date).format(monthFormat)

export const prepareDate = (date: string | null): Date | string | null => {
  if (!date || !isValidMonthDate(date)) return date
  return moment(date, monthFormat).toISOString()
}
