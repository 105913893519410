<template>
  <div :class="containerClasses">
    <bc-icon
      v-if="icon"
      class="step-header__icon"
      :class="{
        'step-header__icon--compact': compact
      }"
      color="var(--bc-primary)"
      :font-size="compact ? '32px' : '62px'"
      :type="iconType"
      >{{ icon }}</bc-icon
    >
    <div class="step-header__title-container">
      <span v-if="title" class="step-header__title">{{ title }}</span>
      <span v-if="subtitle" class="step-header__subtitle">{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { BcIcon } from '../bc-icon'

export default defineComponent({
  name: 'BcWizardStepHeader',
  components: { BcIcon },
  props: {
    title: { type: String, require: true },
    subtitle: { type: String, require: false, default: null },
    icon: { type: String, require: true },
    iconType: { type: String, require: false, default: undefined },
    compact: { type: Boolean, default: false }
  },
  computed: {
    containerClasses() {
      return {
        'step-header': true,
        compact: this.compact
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/_dark.scss';
.step-header {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  justify-items: center;
  align-items: center;

  &__title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: var(--bc-font-size--headline);
    font-weight: bold;
    color: var(--bc-primary);
    margin: 20px 0;
  }

  &__subtitle {
    font-size: var(--bc-font-size--title);
    line-height: var(--bc-font-size--title);
    color: var(--bc-title-color);
  }

  &__icon {
    font-size: 62px;

    &--compact {
      font-size: 32px;
    }
  }

  &.compact {
    margin: 0;
    flex-direction: row;

    .step-header--title-container {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-left: 20px;
    }

    .step-header--title {
      font-size: var(--bc-font-size--body);
      margin: 0;
    }
    .step-header--subtitle {
      font-size: var(--bc-font-size--caption);
    }
  }
}
</style>
