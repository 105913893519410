<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VAppBar } from 'vuetify/lib'
export default defineComponent({
  name: 'BcAppBar',
  extends: VAppBar,
  props: {
    height: {
      type: [Number, String],
      default: 50
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-app-bar.primary-gray {
  background-color: $app-bar-background-color !important;
}

.v-toolbar {
  z-index: 100;
}
</style>
