<template>
  <div ref="activatorRef" class="bc-popover">
    <slot
      name="activator"
      :on="activatorProps.listeners"
      :attrs="activatorProps.attributes"
      :value="showContent"
    ></slot>
    <div
      v-if="contentMounted"
      ref="contentRef"
      class="bc-popover__content"
      :class="contentClass"
      :data-with-header="hasHeader"
    >
      <div class="bc-popover__arrow" data-popper-arrow></div>
      <bc-btn
        icon
        v-if="closeButton"
        size="16"
        class="bc-popover__close-icon"
        @click="closeContent()"
      >
        <bc-icon color="light-grey" size="16">fa-times</bc-icon>
      </bc-btn>
      <div
        v-if="hasHeader"
        class="bc-popover__header"
        :style="headerCssVars"
        :class="headerClass"
      >
        <slot name="header"></slot>
      </div>
      <div class="bc-popover__body" :class="bodyClass">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { createPopper } from '@popperjs/core'
import type { Instance as Popper } from '@popperjs/core'
import {
  defineComponent,
  ref,
  watch,
  computed,
  onUnmounted
} from '@vue/composition-api'
import type { PropType } from '@vue/composition-api'

import { useTeleport, useClickOutside } from '../../composables'
import { BcBtn } from '../bc-btn'
import { BcIcon } from '../bc-icon'

export type BcPopoverPlacement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'bottom'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'

export default defineComponent({
  name: 'BcPopover',
  components: { BcBtn, BcIcon },
  props: {
    value: {
      type: Boolean,
      dafault: undefined
    },
    attach: {
      type: [String, HTMLElement, Boolean] as PropType<
        string | HTMLElement | boolean
      >,
      default: false
    },
    activator: {
      type: [String, HTMLElement] as PropType<string | HTMLElement>,
      default: undefined
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    closeButton: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String as PropType<BcPopoverPlacement>,
      default: 'auto'
    },
    offset: {
      type: Array as unknown as PropType<[number, number]>,
      default: () => [0, 12]
    },
    contentClass: {
      type: String,
      default: undefined
    },
    headerClass: {
      type: String,
      default: undefined
    },
    bodyClass: {
      type: String,
      default: undefined
    }
  },
  setup(props, ctx) {
    const contentRef = ref<HTMLElement | null>(null)
    const activatorRef = ref<HTMLElement | null>(null)
    const contentMounted = ref<boolean>(props.value || false)
    const showContent = ref<boolean>(props.value || false)
    const popper = ref<Popper | null>(null)
    const resizeObserver = new ResizeObserver(() => popper.value?.update())

    const closeContent = () => {
      showContent.value = false
    }
    useTeleport(props.attach, contentRef)
    useClickOutside(
      contentRef,
      () => {
        if (props.closeOnClick) {
          closeContent()
        }
      },
      () => showContent.value
    )

    const mountPopover = () => {
      const hasActivatorSlot = !!ctx.slots.activator
      const activator = hasActivatorSlot ? activatorRef.value : props.activator
      const content = contentRef.value
      const popperInstance = popper.value
      const referece =
        typeof activator === 'string'
          ? document.querySelector(activator)
          : activator

      if (!referece || !content || popperInstance) return

      const placement = props.placement
      const modifiers = [
        {
          name: 'offset',
          options: {
            offset: props.offset
          }
        },
        {
          name: 'preventOverflow',
          options: {
            padding: {
              top: 70,
              left: 20,
              right: 20,
              bottom: 20
            }
          }
        }
      ]

      popper.value = createPopper(referece, content, {
        placement,
        modifiers
      })
    }

    watch(
      () => contentRef.value,
      () => {
        if (contentRef.value) {
          mountPopover()
          resizeObserver.observe(contentRef.value)
        }
      }
    )

    onUnmounted(() => {
      resizeObserver.disconnect()
    })

    const headerCssVars = computed(() => {
      return {
        '--header-padding': props.closeButton
          ? ' 12px 40px 12px 20px'
          : ' 12px 20px'
      }
    })

    watch(
      () => props.value,
      (value) => (showContent.value = value),
      { immediate: true }
    )

    watch(
      () => showContent.value,
      (show) => {
        if (show && !contentMounted.value) {
          contentMounted.value = true
        } else if (contentMounted.value) {
          contentRef.value!.style.display = show ? '' : 'none'
        }
        ctx.emit('input', show)
      }
    )

    const activatorProps = computed(() => ({
      listeners: {
        click: (event: MouseEvent) => {
          event.stopPropagation()
          showContent.value = !showContent.value
        }
      },
      attributes: {
        role: 'button',
        'aria-haspopup': true,
        'aria-expanded': String(showContent.value)
      }
    }))

    const hasHeader = computed(() => !!ctx.slots.header)

    return {
      activatorRef,
      contentRef,
      contentMounted,
      showContent,
      closeContent,
      activatorProps,
      hasHeader,
      headerCssVars
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

$border-width: 12px;
$arrow-width: #{$border-width * 2};

.bc-popover {
  display: inline-block;

  &__content {
    background: $popover-body-background-color;
    border: 1px solid $popover-border-color;
    z-index: 10000;
  }

  &__close-icon {
    position: absolute;
    right: 0;
    color: $light-grey;
    top: 0;
  }

  &__arrow,
  &__arrow::before,
  &__arrow::after {
    position: absolute;
    width: $arrow-width;
    height: $arrow-width;
  }

  &__arrow {
    visibility: hidden;

    &::before {
      visibility: visible;
      content: '';
      width: 0;
      height: 0;
      border: #{$border-width + 1} solid transparent;
      border-bottom-color: $popover-border-color;
    }
    &::after {
      visibility: visible;
      content: '';
      width: 0;
      height: 0;
      border: $border-width solid transparent;
      border-bottom-color: $popover-body-background-color;
    }
  }

  &__content[data-popper-placement^='bottom'][data-with-header^='true']
    > &__arrow {
    &::after {
      border-bottom-color: $popover-header-background-color;
    }
  }

  &__content[data-popper-placement^='bottom'] > &__arrow {
    top: #{-$arrow-width};
    &::before {
      top: -2px;
      left: -1px;
    }
  }
  &__content[data-popper-placement^='top'] > &__arrow {
    bottom: #{-$arrow-width};
    &::before {
      top: 1px;
      left: -1px;
    }
    &::before,
    &::after {
      transform: rotate(180deg);
    }
  }
  &__content[data-popper-placement^='left'] > &__arrow {
    right: #{-$arrow-width};
    &::before {
      top: -1px;
      left: 1px;
    }
    &::before,
    &::after {
      transform: rotate(90deg);
    }
  }
  &__content[data-popper-placement^='right'] > &__arrow {
    left: #{-$arrow-width};
    &::before {
      top: -1px;
      left: -2px;
    }

    &::before,
    &::after {
      transform: rotate(-90deg);
    }
  }

  &__header {
    background: $popover-header-background-color;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: $popover-header-color;
    padding: var(--header-padding);
  }

  &__body {
    font-size: 14px;
    color: $popover-body-color;
    padding: 10px 20px 20px;
  }
}
</style>
