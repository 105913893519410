<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1047 1036.5"
    class="logo"
    :class="className"
    :style="style"
  >
    <defs>
      <linearGradient
        id="a"
        x1="-453.7"
        y1="1061.2"
        x2="-443.4"
        y2="1058.1"
        gradientTransform="matrix(55.5 0 0 -107.47 25202 114364)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#24bbef" />
        <stop offset=".7" stop-color="#21a0e2" />
        <stop offset="1" stop-color="#21a0e2" />
      </linearGradient>
    </defs>
    <g>
      <g>
        <path
          d="M367 0 158 99h-1v157L0 361v314l157 105v157l210 99V921l-105-50V765l39-39a52 52 0 0 0 13 2 53 53 0 1 0-50-39l-21 21-138-91V418l137-91 21 21a53 53 0 1 0 37-37l-38-39V166l105-50 123 59 45-95Z"
          style="fill: url(#a)"
        />
        <path
          d="M890 251V99L681 0 471 99v393h-59a52 52 0 1 0 0 52h59v327l-105 50v115l210-99V544h59a52 52 0 1 0 0-52h-59V166l105-50 104 50v107l-37 38a52 52 0 0 0-15-2 52 52 0 1 0 52 52 53 53 0 0 0-1-12l25-25 133 89v201l-141 93-18-18a53 53 0 1 0-37 37l39 39v101l-105 50v115l210-99V775l157-105V356Z"
          style="fill: #24bbef"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BcLogo',
  props: {
    size: {
      type: [String, Number],
      default: 'md'
    }
  },
  computed: {
    className() {
      return isNaN(this.size) ? this.size : undefined
    },
    style() {
      if (isNaN(this.size)) return undefined

      const width = Number(this.size)
      return `width: ${width}px;`
    }
  }
})
</script>

<style lang="scss" scoped>
.logo {
  &.sm {
    width: 16px;
  }
  &.md {
    width: 32px;
  }
  &.lg {
    width: 64px;
  }
}
</style>
