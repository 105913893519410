<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VMain } from 'vuetify/lib'
export default defineComponent({
  name: 'BcMain',
  extends: VMain
})
</script>

<style lang="scss" scoped>
.v-main__wrap {
  padding: 10px;
}
</style>
