<template>
  <v-expansion-panel-content
    v-bind="$attrs"
    v-on="$listeners"
    :class="`layer-${actualLayer}`"
  >
    <slot></slot>
  </v-expansion-panel-content>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from '@vue/composition-api'
import type { Ref } from '@vue/composition-api'
import { VExpansionPanelContent } from 'vuetify/lib'

export default defineComponent({
  name: 'BcExpansionPanelContent',
  components: { VExpansionPanelContent },
  props: {
    layer: {
      type: [String, Number],
      default: undefined
    }
  },
  setup(props) {
    const parentLayer = inject<Ref<string | number>>('layer')
    const actualLayer = computed(() => props.layer || parentLayer?.value)

    return { actualLayer }
  }
})
</script>

<style lang="scss" scoped>
.v-expansion-panel-content {
  margin-top: 2px;
}
</style>
