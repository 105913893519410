<script lang="ts">
import {
  defineComponent,
  provide,
  inject,
  computed
} from '@vue/composition-api'
import type { Ref } from '@vue/composition-api'
import { VExpansionPanel } from 'vuetify/lib'

export default defineComponent({
  name: 'BcExpansionPanel',
  extends: VExpansionPanel,
  props: {
    layer: {
      type: [String, Number],
      default: undefined
    }
  },
  setup(props) {
    const parentLayer = inject<Ref<string | number>>('layer')
    const actualLayer = computed(() => props.layer || parentLayer?.value)
    provide('layer', actualLayer)
  }
})
</script>
<style lang="scss" scoped>
.v-expansion-panel {
  background-color: transparent !important;

  &::before,
  &:not(:first-child)::after {
    content: unset;
  }

  &:not(:first-child),
  & + .v-expansion-panel {
    margin-top: 10px;
  }
}
</style>
