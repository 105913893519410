<template>
  <bc-breadcrumbs id="breadcrumb-router" :items="breadCrumbs">
    <template v-slot:divider>
      <bc-icon color="#6E7B8A" size="10px">fa-caret-right</bc-icon>
    </template>
    <template v-slot:item="{ item }">
      <bc-breadcrumbs-item :to="item.to" :disabled="item.disabled" exact>
        {{ $t(item.name) }}
      </bc-breadcrumbs-item>
    </template>
  </bc-breadcrumbs>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

import { BcBreadcrumbs } from '../bc-breadcrumbs'
import { BcBreadcrumbsItem } from '../bc-breadcrumbs-item'
import { BcIcon } from '../bc-icon'

export default defineComponent({
  name: 'BcBreadcrumbsRouter',
  components: { BcBreadcrumbs, BcBreadcrumbsItem, BcIcon },
  computed: {
    breadCrumbs() {
      const breadCrumb = this.$route.meta.breadCrumb as any
      if (typeof breadCrumb === 'function') {
        return breadCrumb.call(this, this.$route)
      }
      return breadCrumb
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

#breadcrumb-router {
  display: flex;
  align-items: flex-start;
  min-height: $main-toolbar-height;
  padding: 0;
  margin: 10px 10px 0;
  z-index: 1;
  line-height: 1;
}
</style>
