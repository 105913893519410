<template>
  <div class="step-item">
    <div v-if="hasHeader && compact" class="step-container">
      <bc-wizard-step-header
        :title="title"
        :subtitle="subtitle"
        :icon="icon"
        :iconType="iconType"
        compact
      />
    </div>
    <div v-if="!!status" class="step-header">
      <span class="step-header__status">{{ status }}</span>
    </div>
    <div class="step-container">
      <bc-wizard-step-header
        v-if="hasHeader && !compact"
        :title="title"
        :subtitle="subtitle"
        :icon="icon"
        :iconType="iconType"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { BcWizardStepHeader } from '../bc-wizard-step-header'

export default defineComponent({
  name: 'BcWizardStepPanel',
  components: { BcWizardStepHeader },
  props: {
    title: { type: String, require: true },
    subtitle: { type: String, require: false, default: null },
    icon: { type: String, require: true },
    iconType: { type: String, require: false, default: undefined },
    compact: { type: Boolean, default: false },
    status: {
      type: String,
      require: false,
      default: null
    }
  },
  computed: {
    hasHeader() {
      return !!this.title && !!this.icon
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/_dark.scss';

.step-item {

  .step-header,
  .step-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;

    background: var(--bc-layer-2-bg);
    margin-top: 10px;
  }

  .step-container {
    padding: 20px;
  }

  .step-header {
    padding: 0 20px;
    
    &__status {
      font-size: var(--bc-font-size--body);
      color: var(--bc-title-color);
      font-weight: bold;
      text-transform: uppercase;
      margin: 14px 0;
    }
  }
}
</style>
