<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VProgressLinear } from 'vuetify/lib'
export default defineComponent({
  name: 'BcProgressLinear',
  extends: VProgressLinear,
  props: {
    rounded: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: 'extra-light-grey'
    },
    height: {
      type: [String, Number],
      default: 10
    }
  }
})
</script>
