<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    @blur="$emit('blur', $event)"
    :persistent-placeholder="persistentPlaceholder"
    :class="{ 'bc-text-field--embedded': embedded }"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VTextField } from 'vuetify/lib'
export default defineComponent({
  name: 'BcTextField',
  components: { VTextField },
  props: {
    persistentPlaceholder: {
      type: Boolean,
      default: true
    },
    embedded: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.bc-text-field--embedded {
  margin-top: 0;
  padding-top: 0;

  ::v-deep .v-input__slot::before,
  ::v-deep .v-input__slot::after {
    content: unset;
  }

  ::v-deep input {
    background-color: $black;
    padding: 10px;
  }
}
</style>
