<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VTextarea } from 'vuetify/lib'
export default defineComponent({
  name: 'BcTextarea',
  extends: VTextarea,
  props: {
    persistentPlaceholder: {
      type: Boolean,
      default: true
    },
    rows: {
      type: [Number, String],
      default: 1
    }
  }
})
</script>
