<template>
  <bc-popover v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator="popover">
      <bc-tooltip v-bind="$attrs" v-on="$listeners" top>
        <template v-slot:activator="tooltip">
          <bc-icon
            color="#959DB5"
            size="22"
            v-bind="{ ...tooltip.attrs, ...popover.attrs }"
            v-on="{ ...tooltip.on, ...popover.on }"
            >{{ icon }}</bc-icon
          >
        </template>
        <span>{{ tooltip }}</span>
      </bc-tooltip>
    </template>
    <template v-if="hasHeader" v-slot:header>
      <slot name="header"></slot>
    </template>
    <slot></slot>
  </bc-popover>
</template>

<script lang="ts">
import { defineComponent, computed } from '@vue/composition-api'

import BcIcon from '../bc-icon/BcIcon.vue'
import BcPopover from '../bc-popover/BcPopover.vue'
import BcTooltip from '../bc-tooltip/BcTooltip.vue'

export default defineComponent({
  name: 'BcPopoverIcon',
  components: { BcPopover, BcIcon, BcTooltip },
  props: {
    icon: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      required: false
    }
  },
  setup(_props, ctx) {
    const hasHeader = computed(() => !!ctx.slots.header)
    return { hasHeader }
  }
})
</script>

<style lang="scss" scoped></style>
