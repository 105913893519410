<template>
  <v-stepper
    ref="stepperRef"
    v-bind="$attrs"
    v-on="listeners"
    :alt-labels="altLabels"
    :elevation="elevation"
    :style="`--bc-stepper-progress: ${progress * 100}%`"
  >
    <slot></slot>
  </v-stepper>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from '@vue/composition-api'
import { VStepper } from 'vuetify/lib'

export default defineComponent({
  name: 'BcStepper',
  components: { VStepper },
  model: {
    event: 'change'
  },
  props: {
    altLabels: {
      type: Boolean,
      default: true
    },
    elevation: {
      type: [Number, String],
      default: 0
    }
  },
  setup() {
    const stepperRef = ref<typeof VStepper>()

    const progress = computed(() => {
      const stepCount = stepperRef.value?.steps?.length
      const currentStep = stepperRef.value?.internalValue

      if (!stepCount || !currentStep) return 0

      return (currentStep - 1) / (stepCount - 1)
    })

    return { stepperRef, progress }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-stepper {
  background: none;
}

::v-deep .v-stepper__step--active .v-stepper__label,
::v-deep .v-stepper__step--complete .v-stepper__label {
  color: $blue !important;
  text-shadow: none !important;
}

::v-deep .v-stepper__step--error {
  & .v-stepper__label {
    color: $red !important;
  }

  & .v-stepper__step__step.v-stepper__step__step {
    background-color: $red !important;
    border-color: $light !important;
  }
}
</style>
