import _Vue from 'vue'

import Confirm from './confirm'
import { PluginOptions } from './types'

const pluginList = [Confirm]

export default {
  install(Vue: typeof _Vue, pluginOptions: PluginOptions): void {
    const $brain = Vue.prototype.$brain || {}
    Vue.prototype.$brain = $brain

    pluginList.forEach((Plugin) => {
      Plugin.install({ ...pluginOptions, Vue, $brain })
    })
  }
}
