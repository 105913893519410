import {
  defineComponent,
  provide,
  inject,
  computed,
  ref
} from '@vue/composition-api'
import type { PropType, Ref } from '@vue/composition-api'

const CALENDAR_EVENTS_KEY = 'calendar-events'

export interface CalendarEvent {
  id: number
  name?: string
  date: Date
  color?: string
}

export interface CalendarEventsMixin {
  calendarEvents: Array<CalendarEvent>
  [CALENDAR_EVENTS_KEY]: Ref<CalendarEvent[]>
}

/**
 * Provides the list of calendar events to the component.
 */
const calendarEventsMixin = {
  inject: {
    [CALENDAR_EVENTS_KEY]: {
      default: (): { value: CalendarEvent[] } => ({
        value: [] as CalendarEvent[]
      })
    }
  },
  computed: {
    calendarEvents(this: CalendarEventsMixin): Array<CalendarEvent> {
      return this[CALENDAR_EVENTS_KEY].value
    }
  }
}

/**
 * Gets the list of calendar events provided by the application.
 * @returns An array of calendar events.
 */
const useCalendarEvents = (): Ref<Array<CalendarEvent>> => {
  const fallback = ref<Array<CalendarEvent>>([])
  const calendarEvents = inject<Ref<Array<CalendarEvent>>>(
    CALENDAR_EVENTS_KEY,
    fallback
  )
  return calendarEvents
}

const BcCalendarEventsProvider = defineComponent({
  name: 'BcCalendarEventsProvider',
  props: {
    events: {
      type: Array as PropType<Array<CalendarEvent>>,
      required: true
    },
    defaultColor: {
      type: String,
      default: '#D2354F'
    }
  },
  setup(props) {
    const calendarEvents = computed(() =>
      props.events.map((event) => ({
        ...event,
        color: event.color || props.defaultColor
      }))
    )

    provide(CALENDAR_EVENTS_KEY, calendarEvents)
  },
  render(h) {
    return h('div', this.$slots.default)
  }
})

export { BcCalendarEventsProvider, calendarEventsMixin, useCalendarEvents }
