<template>
  <bc-date-time-field
    v-bind="$attrs"
    v-on="$listeners"
    type="time"
  ></bc-date-time-field>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

import { BcDateTimeField } from '../bc-date-time-field'
export default defineComponent({
  name: 'BcTimeField',
  components: { BcDateTimeField }
})
</script>
