<template>
  <v-stepper-step v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-stepper-step>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VStepperStep } from 'vuetify/lib'

export default defineComponent({
  name: 'BcStepperStep',
  components: { VStepperStep }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

::v-deep .v-stepper__label {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: $light-grey !important;
}

::v-deep .v-stepper__step__step.v-stepper__step__step {
  border: 2px solid $black !important;
  background-color: $light !important;

  &.primary {
    background-color: $blue !important;
    border: 2px solid $light !important;
  }
}

::v-deep .v-stepper__step__step {
  & .v-icon.v-icon {
    font-size: 0;
  }
}
</style>
