<template>
  <bc-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :append-icon="appendIcon"
    :type="inputType"
    @click:append="toggleShowContent()"
  ></bc-text-field>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from '@vue/composition-api'

import { InputType } from '../../enums'
import { BcTextField } from '../bc-text-field'
export default defineComponent({
  name: 'BcPasswordField',
  components: { BcTextField },
  setup() {
    const showContent = ref(false)
    const toggleShowContent = (): void => {
      showContent.value = !showContent.value
    }
    const appendIcon = computed(() =>
      showContent.value ? 'fa-eye' : 'fa-eye-slash'
    )
    const inputType = computed(() =>
      showContent.value ? InputType.TEXT : InputType.PASSWORD
    )
    return { showContent, toggleShowContent, inputType, appendIcon }
  }
})
</script>
