<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VFooter } from 'vuetify/lib'
export default defineComponent({
  name: 'BcFooter',
  extends: VFooter
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-footer.primary-gray {
  background-color: $app-footer-background-color !important;
}
</style>
