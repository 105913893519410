<script lang="ts">
import { defineComponent, toRef, provide } from '@vue/composition-api'
import { VExpansionPanels } from 'vuetify/lib'
export default defineComponent({
  name: 'BcExpansionPanels',
  extends: VExpansionPanels,
  props: {
    layer: {
      type: [String, Number],
      default: 2
    }
  },
  setup(props) {
    const layerRef = toRef(props, 'layer')
    provide('layer', layerRef)
  }
})
</script>
