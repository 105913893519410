/* eslint-disable */
// @ts-nocheck
class BrainToast {
  options: any
  defaultOptions: any
  toastsContainer: any
  toasts: Array<any>
  id: number
  constructor(options: any) {
    this.options = {
      icons: {
        close: 'fa-times',
        success: 'fa-check-circle',
        error: 'fa-exclamation-circle',
        info: 'fa-info-circle',
        warning: 'fa-exclamation-circle',
        custom: 'fa-home'
      }
    }
    this.options = { ...this.options, ...options }
    this.defaultOptions = {
      preloader: true,
      actions: []
    }
    this.id = 1
    this.toasts = []

    const body = document.querySelector('body')

    this.toastsContainer = document.createElement('ul')
    this.toastsContainer.classList.add('bc-toast')
    this.toastsContainer.setAttribute('data-test', 'toast')
    if (body) {
      body.appendChild(this.toastsContainer)
    }
  }

  /**
   * Success toast.
   * @param {object} options
   * @param {string} options.title - used for less message in a big font-size.
   * @param {boolean}options.description - Text contains a more number of words.
   * @param {string} options[].title - text in a button action.
   * @param {string} options[].OnClick - eventListener for click event.
   */
  success(options: any) {
    options = {
      ...this.defaultOptions,
      ...options,
      type: 'success',
      icon: this.options.icons.success
    }
    return this._showToast(options)
  }

  /**
   * Success toast.
   * @param {object} options
   * @param {string} options.title - used for less message in a big font-size.
   * @param {boolean}options.description - Text contains a more number of words.
   * @param {string} options[].title - text in a button action.
   * @param {string} options[].OnClick - eventListener for click event.
   */
  error(options: any) {
    options = {
      ...this.defaultOptions,
      ...options,
      type: 'error',
      icon: this.options.icons.error
    }
    return this._showToast(options)
  }

  /**
   * Success toast.
   * @param {object} options
   * @param {string} options.title - used for less message in a big font-size.
   * @param {boolean}options.description - Text contains a more number of words.
   * @param {string} options[].title - text in a button action.
   * @param {string} options[].OnClick - eventListener for click event.
   */
  info(options: any) {
    options = {
      ...this.defaultOptions,
      ...options,
      type: 'info',
      icon: this.options.icons.info
    }
    return this._showToast(options)
  }

  /**
   * Success toast.
   * @param {object} options
   * @param {string} options.title - used for less message in a big font-size.
   * @param {boolean}options.description - Text contains a more number of words.
   * @param {string} options[].title - text in a button action.
   * @param {string} options[].OnClick - eventListener for click event.
   */
  warning(options: any) {
    options = {
      ...this.defaultOptions,
      ...options,
      type: 'warning',
      icon: this.options.icons.warning
    }
    return this._showToast(options)
  }

  /**
   * Success toast.
   * @param {object} options
   * @param {string} options.title - used for less message in a big font-size.
   * @param {boolean}options.description - Text contains a more number of words.
   * @param {boolean}options.type - a custom type in css file.
   * @param {boolean}options.icon - custom icon.
   * @param {string} options[].title - text in a button action.
   * @param {string} options[].OnClick - eventListener for click event.
   */
  custom(options: any) {
    options = { ...this.defaultOptions, ...options }
    if (options.type == null)
      throw new Error(
        "Brain Toast : You need inform the property type in notify.custom({type:'customType'})"
      )
    if (options.icon == null)
      throw new Error(
        "Brain Toast : You need inform the property type in notify.custom({icon:'fa-customIcon'})"
      )
    return this._showToast(options)
  }

  remove(id: number) {
    this.toasts = this.toasts.filter((toast) => {
      if (toast.id === id) {
        document.querySelector(`#bc-toast-${id}`).remove()
        return true
      }
    })
  }

  getButtonsActions(actions: any) {
    return actions.map((action) => {
      const button = document.createElement('button')
      button.addEventListener('click', action.onClick)
      button.innerHTML = action.title
      return button
    })
  }

  hasPreloader(hasPreloader: any) {
    if (hasPreloader) {
      const divWrapper = document.createElement('div')
      divWrapper.classList.add('bc-toast__progress')
      const progress = document.createElement('progress')
      progress.setAttribute('max', '100')
      progress.setAttribute('value', '0')
      progress.classList.add('bc-toast__bar')
      divWrapper.appendChild(progress)
      return divWrapper
    }
    return null
  }

  _showToast({
    type,
    title = '',
    description = '',
    actions,
    preloader,
    icon,
    timeout = 4000
  }) {
    const newId = this.id || 1
    const toast = document.createElement('li')
    toast.classList.add('bc-toast__item')
    toast.classList.add(`bc-toast__item--${type}`)
    toast.setAttribute('id', `bc-toast-${newId}`)

    const actionAreaElement = document.createElement('div')
    actionAreaElement.classList.add('bc-toast__action')
    toast.innerHTML = `
				<div class="bc-toast__icon"><i class="fas ${icon}"></i></div>
				<div class="bc-toast__close">
					<button id="bc-toast-close-${newId}"><i class="fas ${this.options.icons.close}"></i></button>
				</div>
				<div class="bc-toast__title">${title} </div>
		`

    const descriptionElement = document.createElement('div')
    descriptionElement.classList.add('bc-toast__description')
    descriptionElement.innerHTML = description

    function setFullDescription(event: any) {
      descriptionElement.innerText = description

      if (!event) return

      const viewLess = document.createElement('button')
      viewLess.innerText = 'View less'
      viewLess.addEventListener('click', setShortDescription)
      viewLess.style.marginLeft = '5px'
      descriptionElement.appendChild(viewLess)
    }

    function setShortDescription() {
      const viewMore = document.createElement('button')
      viewMore.innerText = ' View more'
      viewMore.addEventListener('click', setFullDescription)
      viewMore.style.marginLeft = '5px'

      descriptionElement.innerText = description.slice(0, 99) + '...'
      descriptionElement.appendChild(viewMore)
    }

    if (description.length > 100) {
      setShortDescription()
    } else {
      setFullDescription(null)
    }
    toast.appendChild(descriptionElement)

    const _actions = this.getButtonsActions(actions)
    if (_actions.length > 0) {
      _actions.map((_action) => actionAreaElement.appendChild(_action))
      toast.appendChild(actionAreaElement)
    }

    const _preloader = this.hasPreloader(preloader)
    if (_preloader) {
      toast.appendChild(_preloader)
    }
    const isVisible = () => {
      const toastsElementsInBrawser = Array.from(
        document.querySelectorAll('.bc-toast__item')
      ).slice(0, 3)
      return toastsElementsInBrawser.find((t) => {
        return t.id === `bc-toast-${newId}`
      })
    }
    const toastObject = {
      id: newId,
      timeout,
      counter: 0,
      isMouseOut: true
    }
    if (toastObject.timeout > 0) {
      toastObject.timer = setInterval(() => {
        if (!isVisible(newId) || !toastObject.isMouseOut) {
          return
        }
        toastObject.counter += 1000 / toastObject.timeout
        if (preloader) {
          _preloader.firstChild.value = 100 - toastObject.counter
        }

        if (toastObject.counter >= 100) {
          toast.remove()
          clearInterval(toastObject.timer)
          this.toasts = this.toasts.filter((toast) => {
            return toast.id === toastObject.id
          })
        }
      }, 10)
    }

    toast.addEventListener('mouseenter', handleMouseOver)
    toast.addEventListener('touchstart', handleMouseOver)
    toast.addEventListener('touchend', handleMouseOut)
    document.body.addEventListener('touchend', handleMouseOut)
    toast.addEventListener('mouseleave', handleMouseOut)

    function handleMouseOver() {
      toastObject.isMouseOut = false
    }

    function handleMouseOut() {
      toastObject.isMouseOut = true
    }

    const closeButton = toast.querySelector('.bc-toast__close > button')
    closeButton.addEventListener('click', () => {
      if (!isVisible(newId)) return
      toast.remove(newId)
      clearInterval(toastObject.timer)
      this.toasts = this.toasts.filter((toast) => {
        return toast.id === toastObject.id
      })
    })

    this.toasts.push(toastObject)
    this.toastsContainer.appendChild(toast)

    return this.id++
  }
}

const notify = () => {
  const toast = new BrainToast({})

  return {
    success: (options: any) => toast.success(options),
    info: (options: any) => toast.info(options),
    error: (options: any) => toast.error(options),
    warning: (options: any) => toast.warning(options),
    custom: (options: any) => toast.custom(options)
  }
}
export default notify()

export { BrainToast }
