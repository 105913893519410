<template>
  <component
    v-bind="$attrs"
    v-on="$listeners"
    :is="_inputComponent"
    :input-value="value"
    :label="_label"
    :items="items"
    :value="value"
    :error-messages="_errorMessages"
    clear-icon="fa-times"
    :clearable="!required"
    @setFieldError="$emit('setFieldError', $event)"
    filled
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :type="type"
  />
</template>

<script>
import { defineComponent, computed, onMounted } from '@vue/composition-api'

import { InputType } from '../../enums/'
import { BcAutocomplete } from '../bc-autocomplete'
import { BcCheckbox } from '../bc-checkbox'
import { BcDateField } from '../bc-date-field'
import { BcDateTimeField } from '../bc-date-time-field'
import { BcMonthField } from '../bc-month-field'
import { BcMultiselect } from '../bc-multiselect'
import { BcNumericField } from '../bc-numeric-field'
import { BcPasswordField } from '../bc-password-field'
import { BcSelect } from '../bc-select'
import { BcSwitch } from '../bc-switch'
import { BcTextField } from '../bc-text-field'
import { BcTextarea } from '../bc-textarea'
import { BcTimeField } from '../bc-time-field'

export default defineComponent({
  name: 'BcFormInput',
  components: {
    BcSwitch,
    BcCheckbox,
    BcSelect,
    BcTextField,
    BcDateTimeField,
    BcMonthField,
    BcPasswordField,
    BcMultiselect
  },
  props: {
    value: {
      require: true
    },
    type: {
      type: String,
      default: InputType.TEXT,
      require: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      default: () => []
    },
    errorMessages: {
      type: [Array, Object, String],
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    onMounted(() => {
      if (isBoolean() && !props.value) {
        context.emit('change', false)
      }
    })
    const isBoolean = () =>
      [InputType.TOGGLE, InputType.CHECKBOX].includes(props.type)
    const _label = computed(
      () => `${props?.label} ${props.required ? '*' : ''}`
    )

    const _errorMessages = computed(() => {
      if (props.errorMessages) {
        const messages = Array.isArray(props.errorMessages)
          ? props.errorMessages
          : [props.errorMessages]
        return messages.map((error) =>
          typeof error === 'string'
            ? context.root.$t(error)
            : context.root.$t(error.key, error.args)
        )
      }
    })
    const _inputComponent = computed(() => {
      switch (props.type) {
        case InputType.CHECKBOX:
          return BcCheckbox
        case InputType.SWITCH:
          return BcSwitch
        case InputType.TEXTAREA:
          return BcTextarea
        case InputType.TEXT:
          return BcTextField
        case InputType.DATETIME:
          return BcDateTimeField
        case InputType.DATE:
          return BcDateField
        case InputType.MONTH:
          return BcMonthField
        case InputType.TIME:
          return BcTimeField
        case InputType.AUTOCOMPLETE:
          return BcAutocomplete
        case InputType.SELECT:
          return BcSelect
        case InputType.MULTISELECT:
          return BcMultiselect
        case InputType.PASSWORD:
          return BcPasswordField
        case InputType.NUMERIC:
          return BcNumericField
        default:
          return BcTextField
      }
    })

    return { isBoolean, _label, _errorMessages, _inputComponent }
  }
})
</script>
