<template>
  <div class="v-input">
    <bc-menu
      v-model="showPicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ attrs }">
        <bc-text-field
          v-bind="{ ...attrs, ...$attrs }"
          v-on="{ ...$on }"
          v-model="textValue"
          :append-icon="'fa-calendar-alt'"
          @click:append="onOpenPickerHandler"
          @input="onInputHandler"
          type="text"
        ></bc-text-field>
      </template>

      <!-- Date Picker -->
      <bc-date-picker
        v-bind="datePickerProps.attrs"
        v-on="datePickerProps.on"
        type="month"
        v-if="showPicker"
        v-model="datePickerValue"
        @input="onClosePickerHandler"
      ></bc-date-picker>
      <!-- Date Picker -->
    </bc-menu>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  watchEffect
} from '@vue/composition-api'
import type { PropType } from '@vue/composition-api'

import { BcDatePicker } from '../bc-date-picker'
import { BcIcon } from '../bc-icon'
import { BcTab } from '../bc-tab'
import { BcTabItem } from '../bc-tab-item'
import { BcTabs } from '../bc-tabs'
import { BcTabsItems } from '../bc-tabs-items'
import { BcTimePicker } from '../bc-time-picker'
import {
  parseDate,
  formatDate,
  parsePickerDate,
  isValidDate,
  toDateString,
  prepareDate
} from './monthPickerHelpers'

export default defineComponent({
  name: 'BcMonthField',
  components: {
    BcTimePicker,
    BcDatePicker,
    BcTabs,
    BcTab,
    BcTabItem,
    BcTabsItems,
    BcIcon
  },
  props: {
    value: {
      type: [String, Date] as PropType<string | Date>,
      default: ''
    },
    format: {
      type: String,
      default: undefined
    },
    defaultValue: {
      type: [String, Date] as PropType<string | Date>,
      default: () => new Date().toISOString()
    }
  },
  setup(props, ctx) {
    const root = ctx.root as any
    const textValue = ref<null | string>(null)
    const showPicker = ref<boolean>(false)

    const formatString = computed<string>(() => {
      if (props.format) return props.format
      return root.$t('core.monthFormat')
    })

    const datePickerValue = computed<string | null>({
      get() {
        if (!props.value) return toDateString(props.defaultValue)
        if (!isValidDate(props.value)) return null
        return toDateString(props.value)
      },
      set(value) {
        const date = parsePickerDate(value)
        formatAndEmit(date)
      }
    })

    const datePickerProps = computed<any>(() => {
      return {
        attrs: {
          ...ctx.attrs
        },
        on: ctx.listeners
      }
    })

    const onOpenPickerHandler = () => {
      showPicker.value = true
    }

    const onClosePickerHandler = () => {
      showPicker.value = false
    }

    const onInputHandler = () => {
      const date = parseDate(textValue.value, formatString.value)
      formatAndEmit(date)
    }

    watchEffect(() => {
      if (isValidDate(props.value)) {
        textValue.value = formatDate(props.value, formatString.value)
      } else {
        textValue.value = (props.value || '') as string
      }
    })

    const formatAndEmit = (date: string | null) => {
      const formatedDate: string | Date | null = prepareDate(date)
      ctx.emit('input', formatedDate)
    }

    return {
      textValue,
      datePickerValue,
      datePickerProps,
      showPicker,
      onOpenPickerHandler,
      onClosePickerHandler,
      onInputHandler
    }
  }
})
</script>

<style lang="scss" scoped></style>
