<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VSwitch } from 'vuetify/lib'
export default defineComponent({
  name: 'BcSwitch',
  extends: VSwitch,
  props: {
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    hideDetails: {
      default: 'auto'
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-input--switch {
  .v-input--switch__thumb {
    color: $switch__thumb_disable_color;
  }

  &.v-input--is-label-active {
    .v-input--switch__thumb {
      color: $switch__thumb_active_color !important;
    }

    .v-label {
      color: $light-grey;
    }
  }

  .v-input--switch__track {
    color: $switch__track_color !important;
  }

  &.v-input--selection-controls {
    margin-top: 0;
    padding: 6.5px 0;

    &:not(.v-input--hide-details) {
      padding-bottom: 0;
    }
  }

  .v-input--selection-controls__input {
    margin-right: 10px;
  }

  label {
    font-size: 14px;
    color: $extra-light-grey;
  }
}
</style>
