<template>
  <v-stepper-items v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-stepper-items>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VStepperItems } from 'vuetify/lib'

export default defineComponent({
  name: 'BcStepperItems',
  components: { VStepperItems }
})
</script>
