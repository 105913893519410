<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VTab } from 'vuetify/lib'
export default defineComponent({
  name: 'BcTab',
  extends: VTab
})
</script>

<style lang="scss" scoped>
.v-tab {
  width: 100%;
}
</style>
