<template>
  <v-expansion-panel-header
    v-bind="$attrs"
    v-on="$listeners"
    :class="`layer-${actualLayer}`"
  >
    <template v-slot:actions>
      <slot name="action" :open="open">
        <bc-icon size="14" class="bc-expansion-panel-header__action">
          {{ expandIcon }}
        </bc-icon>
      </slot>
    </template>
    <template v-slot="{ open }">
      <span class="bc-expansion-panel-header__content">
        <slot :open="open"></slot>
      </span>
    </template>
  </v-expansion-panel-header>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from '@vue/composition-api'
import type { Ref } from '@vue/composition-api'
import { VExpansionPanelHeader } from 'vuetify/lib'

import { BcIcon } from '../bc-icon'

export default defineComponent({
  name: 'BcExpansionPanelHeader',
  components: { VExpansionPanelHeader, BcIcon },
  props: {
    layer: {
      type: [String, Number],
      default: undefined
    }
  },
  setup(props) {
    const parentLayer = inject<Ref<string | number>>('layer')
    const actualLayer = computed(() => props.layer || parentLayer?.value)
    const expandIcon = computed(() =>
      Number(actualLayer.value) > 2
        ? 'fas fa-caret-down'
        : 'fas fa-chevron-down'
    )

    return { actualLayer, expandIcon }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

.bc-expansion-panel-header {
  &__action {
    order: 0;
    margin-right: 10px;
    color: $light-grey !important;
  }

  &__content {
    order: 1;
    font-size: 14px;
    font-weight: bold;
    color: $light-grey;
  }
}
</style>
