<template>
  <bc-select
    multiple
    v-bind="$attrs"
    :chips="chips"
    v-on="$listeners"
  ></bc-select>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

import { BcSelect } from '../bc-select'
export default defineComponent({
  name: 'BcMultiselect',
  components: { BcSelect },
  props: {
    chips: {
      type: Boolean,
      default: true
    }
  }
})
</script>
