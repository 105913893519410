<template>
  <div class="message-body">
    <bc-icon v-if="icon" :class="`message-body__icon ${type}--text`">
      {{ icon }}
    </bc-icon>
    <div :class="`message-body__title ${type}--text`">{{ title }}</div>
    <div v-if="subtitle" class="message-body__subtitle">{{ subtitle }}</div>
    <div v-if="caption" class="message-body__caption">{{ caption }}</div>
    <div class="message-body__actions">
      <div v-for="action in actions" :key="action.label">
        <bc-btn
          v-if="action.type == 'button' && !action.hidden"
          @click.prevent="action.callback"
          :color="action.color"
        >
          {{ action.label }}
        </bc-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'BcMessage',
  props: {
    type: {
      type: String,
      default: 'warning'
    },
    title: {
      type: String,
      require: true
    },
    icon: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    caption: {
      type: String,
      default: null
    },
    actions: {
      type: Array,
      default: []
    }
  }
})
</script>
<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

.message-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  height: calc(100% - 100px);

  & &__icon {
    font-size: 62px !important;
  }

  & &__title {
    font-size: 20px !important;
    font-weight: bold;
    max-width: 590px;
    margin-top: 15px;
  }

  & &__subtitle {
    font-size: 14px !important;
    color: $light-grey !important;
    font-weight: bold;
    max-width: 550px;
    margin-top: 16px;
  }

  & &__caption {
    font-size: 10px !important;
    color: $light-grey !important;
    max-width: 350px;
    margin-top: 18px;
  }

  & &__actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    & > :not(:first-child) {
      margin-left: 20px;
    }
  }
}
</style>
