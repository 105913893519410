<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VTabsItems } from 'vuetify/lib'
export default defineComponent({
  name: 'BcTabsItems',
  extends: VTabsItems
})
</script>

<style lang="scss" scoped>
.v-tabs-items {
  padding: 12px 20px;
  background-color: #262c39;
}
</style>
