<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VRadio } from 'vuetify/lib'
export default defineComponent({
  name: 'BcRadioGroup',
  extends: VRadio
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-radio {
  &.v-item--active {
    .v-icon {
      color: $radio_active_color !important;
    }
  }

  &:not(.v-item--active) {
    .v-icon {
      color: $radio_disable_color;
    }
  }

  .v-icon {
    font-size: $radio-button-icon-size;
  }

  label {
    color: $light-grey !important;
    font-size: 14px;
  }
}
</style>
