const InputType = {
  TEXT: 'text',
  DATE: 'date',
  DATETIME: 'datetime',
  TIME: 'time',
  MONTH: 'month',
  SELECT: 'select',
  MULTISELECT: 'multiselect',
  CHECKBOX: 'checkbox',
  SWITCH: 'switch',
  AUTOCOMPLETE: 'autocomplete',
  PASSWORD: 'password',
  TEXTAREA: 'textarea',
  NUMERIC: 'numeric'
}

export { InputType }
