import { computed } from '@vue/composition-api'
import type { ComputedRef } from '@vue/composition-api'

export interface UseBackgroundColorParams {
  color: string
}

export type UseBackgroundColor = {
  className?: string
  style?: {
    backgroundColor: string
    borderColor: string
  }
}

const useBackgroundColor = ({
  color
}: UseBackgroundColorParams): ComputedRef<UseBackgroundColor> => {
  const backgroundColor = computed(() => {
    const isAColor = color.match(/^(?:#|rgba?\(|hsl\()/)
    const className = isAColor ? undefined : color
    const style = isAColor
      ? {
          backgroundColor: color,
          borderColor: color
        }
      : undefined

    return { className, style }
  })

  return backgroundColor
}

export { useBackgroundColor }
