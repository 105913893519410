<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VContainer } from 'vuetify/lib'
export default defineComponent({
  name: 'BcContainer',
  extends: VContainer,
  props: {
    fluid: {
      type: Boolean,
      default: true
    }
  }
})
</script>
