<template>
  <v-stepper-content v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </v-stepper-content>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VStepperContent } from 'vuetify/lib'

export default defineComponent({
  name: 'BcStepperContent',
  components: { VStepperContent }
})
</script>

<style lang="scss" scoped>
.v-stepper__content,
.v-overlay {
  transition: none;
}
.v-stepper__wrapper {
  transition: none;
}
</style>
