<script lang="ts">
import { defineComponent } from '@vue/composition-api'
import { VTabs } from 'vuetify/lib'
export default defineComponent({
  name: 'BcTabs',
  extends: VTabs
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';
.v-tabs {
  background-color: #2b323f;
  .v-tabs-bar {
    .v-tabs-bar__content {
      .v-tabs-slider-wrapper {
        .v-tabs-slider {
          background-color: $blue;
        }
      }

      .v-tab {
        color: $extra-light-grey;
      }

      .v-tab--active {
        color: $light-grey;
      }

      .v-tab--active,
      .v-tab {
        color: $light-grey;
        font-family: Aller;
        text-transform: uppercase;
      }
    }
  }
}
</style>
