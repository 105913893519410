<template>
  <div class="item-container">
    <component
      :is="hasChildren ? 'span' : 'router-link'"
      :to="hasChildren ? '' : item.href"
      @click="toggle"
      :class="`drawer-item-link drawer-item-link--level-${level}`"
    >
      <div
        class="drawer-item-name drawer-text-light"
        :class="{ 'active-link': active }"
      >
        {{ item.name }}
      </div>
      <bc-icon v-if="hasChildren" :class="expandClasses">
        fa-caret-down
      </bc-icon>
    </component>
    <transition v-show="hasChildren" name="slide">
      <div v-if="expand" class="drawer-subitem-container">
        <bc-drawer-item
          v-for="subitem in children"
          :key="subitem.name"
          :item="subitem"
          :level="level + 1"
        ></bc-drawer-item>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import { BcIcon } from '../bc-icon'

export default defineComponent({
  name: 'BcDrawerItem',
  components: { BcIcon },
  props: {
    item: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      expand: false,
      active: false
    }
  },
  mounted() {
    this.children = this.getActiveChildren(this.item)
    const hasActiveChild = !!this.children?.find((o) => o.active)
    this.active =
      this.isLinkActive(this.item.href, this.item.exact) || hasActiveChild
    this.expand = this.active
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },
    isLinkActive(href, exact) {
      if (!href) return false
      const route = this.$route.fullPath

      if (!exact) {
        const regExp = new RegExp(`${href.replace('/', '\\/')}(?:\\/|$)`)
        return !!route.match(regExp)
      }

      return route === href
    },
    getActiveChildren(item) {
      if (!item.children) return undefined

      return item.children.map((child) => {
        const isActive = this.isLinkActive(child.href, child.exact)
        const children = this.getActiveChildren(child)
        const hasActiveChild = !!children?.find((o) => o.active)

        return {
          ...child,
          children,
          active: isActive || hasActiveChild
        }
      })
    }
  },
  computed: {
    hasChildren() {
      return this.item.children && this.item.children.length > 0
    },
    expandClasses() {
      return {
        'drawer-text-light': true,
        'drawer-item-expand': true,
        expanded: this.expand
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

.drawer-text-light {
  color: $drawer-text-color !important;
}

.drawer-item-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
  font-size: 18px;
  border-bottom: 2px solid $drawer-divider-color;

  & > *:hover {
    opacity: 0.8;
  }
}

.drawer-item-link--level-1 {
  background: $drawer-subitem-background-color;
  padding-left: 15px;
  font-size: 16px;
}

.drawer-item-link--level-2 {
  background: $drawer-subsubitem-background-color;
  padding-left: 30px;
  font-size: 16px;
}

a.drawer-item-link {
  color: inherit;
  text-decoration: none;
}

.drawer-item-name {
  text-transform: uppercase;
  margin-left: 20px;
}

.drawer-item-expand {
  margin-left: auto;
  margin-right: 10px;
  font-size: 16px;
  transform: rotate(0deg);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
}

.drawer-item-expand.expanded {
  transform: rotate(180deg);
}

.drawer-subitem-container {
  padding: 0 0;
  overflow: hidden;
  max-height: 100vh;
}

.active-link {
  font-family: 'Aller', sans-serif;
  font-weight: bold;
  color: $blue !important;
}

.slide-enter-active {
  transition: max-height 0.3s ease-in;
}
.slide-leave-active {
  transition: max-height 0.3s ease-out;
}
.slide-enter,
.slide-leave-to {
  max-height: 0;
}
</style>
