import moment from 'moment'

export const vuetifyDateFormat = 'YYYY-MM-DD'
export const vuetifyTimeFormat = 'HH:mm'
export const vuetifyDateTimeFormat = `${vuetifyDateFormat} ${vuetifyTimeFormat}`

export const isValidDate = (date: string | Date | null): boolean => {
  return moment(date, true).isValid()
}

export const parseDate = (
  dateStr: string | null,
  format: string,
  strict = true
): string | null => {
  if (!dateStr) return null
  const date = moment(dateStr, format, strict)
  return date.isValid() ? date.toISOString() : dateStr
}

export const formatDate = (
  date: Date | string | null,
  format: string
): string | null => {
  if (!date || !isValidDate(date)) return null

  return moment(date).format(format)
}

export const parsePickerDate = (
  date: string | null,
  time: string | null
): string | null => {
  const _date = date || moment().format(vuetifyDateFormat)
  const _time = time || moment().startOf('day').format(vuetifyTimeFormat)
  return parseDate(`${_date} ${_time}`, vuetifyDateTimeFormat)
}

export const toDateString = (date: Date | string): string =>
  moment(date).format(vuetifyDateFormat)

export const toTimeString = (date: Date | string): string =>
  moment(date).format(vuetifyTimeFormat)

export const prepareDate = (
  date: string | null,
  type: string
): Date | string | null => {
  if (!date || !isValidDate(date)) return date

  switch (type) {
    case 'date':
      return moment(date).startOf('day').toISOString()
    default:
      return date
  }
}

export const clampTime = (
  mode: 'min' | 'max',
  value: Date | string,
  limit: Date | string | undefined
): string | undefined => {
  const _value = moment(value)
  const _limit = moment(limit)

  if (!limit || !_limit.isValid()) return undefined
  if (!_value.isValid()) return toTimeString(_limit.toISOString())

  const shouldBeClamped =
    mode === 'min'
      ? moment(value).startOf('day').isBefore(_limit)
      : moment(value).endOf('day').isAfter(_limit)

  return shouldBeClamped ? toTimeString(_limit.toISOString()) : undefined
}
