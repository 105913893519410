import { onUnmounted, onBeforeMount } from '@vue/composition-api'
import type { Ref } from '@vue/composition-api'

export type onClickOutsideHandler = (event: MouseEvent) => boolean | void
export type onClickOutsideIsActiveCondition = (event: MouseEvent) => boolean

const useClickOutside = (
  el: Ref<HTMLElement | null>,
  handler: onClickOutsideHandler,
  isActive: onClickOutsideIsActiveCondition = () => true
): void => {
  const onClickHandler = (event: MouseEvent) => {
    const target = event.target as Node | null

    // Checking if the click is outside can be expensive,
    // avoid checking if there is no active component
    if (!isActive(event)) return true

    if (
      !el.value ||
      !target ||
      (target !== el.value && !el.value.contains(target))
    ) {
      return handler(event)
    }
  }

  onBeforeMount(() => {
    document.addEventListener('click', onClickHandler, true)
  })

  onUnmounted(() => {
    document.removeEventListener('click', onClickHandler, true)
  })
}

export { useClickOutside }
