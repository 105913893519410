/**
 * Convert a PascalCase or cammelCase string to a kebab-case string.
 * @param str string to be converted.
 * @returns a kebab-case string.
 */
const toKebabCase = (str: string): string => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export { toKebabCase }
