<template>
  <bc-text-field
    v-bind="$attrs"
    v-on="$listeners"
    type="number"
  ></bc-text-field>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

import { BcTextField } from '../bc-text-field'
export default defineComponent({
  name: 'BcNumericField',
  components: { BcTextField }
})
</script>
