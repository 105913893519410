import _Vue from 'vue'
import type { PluginFunction } from 'vue'
import Vuetify from 'vuetify/lib'

import * as components from './components/index'
import Plugins from './plugins'

// Import vue components

// install function executed by Vue.use()
const install: PluginFunction<any> = function installUiComponents(
  Vue: typeof _Vue
) {
  Vue.use(Vuetify)
  Vue.use(Plugins)

  Object.entries(components).forEach(([componentName, component]) => {
    Vue.component(componentName, component)
  })
}

// Create module definition for Vue.use()
export default install

// To allow individual component use, export components
// each can be registered via Vue.component()
export * from './components'
export * from './enums'
export * from './providers'
export { default as uiLocales } from './locales'
