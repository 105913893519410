<template>
  <bc-dialog
    v-model="show"
    v-bind="$attrs"
    v-on="$listeners"
    :height="height"
    :width="width"
  >
    <bc-card class="bc-modal" :color="color" :height="height" :width="width">
      <div class="bc-modal__header" :class="headerClass">
        <slot name="header">{{ title }}</slot>
        <bc-spacer />
        <bc-btn
          icon
          small
          color="transparent"
          class="bc-modal__close-btn"
          @click="onClose"
        >
          <bc-icon color="light-grey" size="16">fa-times</bc-icon>
        </bc-btn>
      </div>
      <div class="bc-modal__body" :class="bodyClass">
        <slot></slot>
      </div>
      <div v-if="hasFooter" class="bc-modal__footer" :class="footerClass">
        <bc-spacer />
        <slot name="footer"></slot>
      </div>
    </bc-card>
  </bc-dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from '@vue/composition-api'

import { BcBtn } from '../bc-btn'
import { BcDialog } from '../bc-dialog'
import { BcSpacer } from '../bc-grid'
import { BcIcon } from '../bc-icon'

export default defineComponent({
  name: 'BcModal2',
  components: { BcDialog, BcSpacer, BcBtn, BcIcon },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'medium-grey'
    },
    height: {
      type: [String, Number],
      default: 690
    },
    width: {
      type: [String, Number],
      default: 800
    },
    headerClass: {
      type: String,
      default: undefined
    },
    bodyClass: {
      type: String,
      default: undefined
    },
    footerClass: {
      type: String,
      default: undefined
    }
  },
  setup(props, ctx) {
    const hasFooter = computed(() => !!ctx.slots.footer)
    const show = computed<boolean>({
      get: () => props.value,
      set: (_show) => ctx.emit('input', _show)
    })

    const onClose = () => ctx.emit('input', false)

    return { show, hasFooter, onClose }
  }
})
</script>

<style lang="scss" scoped>
@import '../../styles/brain-ui-variables.scss';

.bc-modal {
  padding: 10px 20px 20px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 max-content;
    border-bottom: 1px solid $modal-border-color;

    text-transform: uppercase;
    font-size: 16px;
  }

  &__body {
    padding: 10px 0 20px;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    align-items: center;
    flex: 0 0 max-content;
    border-top: 1px solid $modal-border-color;
    padding-top: 10px;
  }

  &__close-btn {
    margin-right: -12px;
  }
}
</style>
